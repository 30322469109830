.primaryButton {
    display: inline-block;
    outline: none;

    font-family: Source Sans Pro;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: normal;
    text-align: center;
    padding: 10px 15px !important;
    font-weight: 300;
    color: #fff;

    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.7);
    margin: 0 10px 10px 0;
    min-width: 150px;

    text-decoration: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
        supported by Chrome and Opera */

    &:after {
        transition: all 300ms ease;

        text-decoration: inherit;
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        content: '\f178';

        position: relative;
        margin-left: 10px;
        font-size: 16px;
        color: #d6a76f;
        top: 1px;
    }

    &:hover {
        background: #d6a76f;
        border-color: #d6a76f;
        text-decoration: none !important;
        transition: all 300ms ease;

        -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.8) 30%, #000 50%, rgba(0, 0, 0, 0.8) 70%);
        animation: shine 2s infinite;
        -webkit-mask-size: 200%;
        color: #fff !important;

        &:after {
            color: #fff;
        }
    }

    &:focus, &:hover{
        outline: none;
    }

    @-webkit-keyframes shine {
        from {
            -webkit-mask-position: 150%;

            &:after {
                margin-left: 10px;
            }
        }

        to {
            -webkit-mask-position: -50%;

            &:after {
                margin-left: 15px;
            }
        }
    }

    @media only screen and (max-width: 420px) {
        line-height: normal;
        text-align: center;
        padding: 12px 17px;
        height: auto;

        margin-bottom: 15px;
        margin-right: 0;
        margin-left: 0;
    }

    @media only screen and (max-width: 320px) {
        width: 80%;
    }

    &.isPWA {
        border-radius: 25px;
        -moz-border-radius: 25px;
        -webkit-border-radius: 25px;
    }

    &.gold {
        background: #d6a76f;
        color: #fff;

        &:after {
            color: #fff;
        }
    }

    &.leftAlign {
        text-align: left;
    }
    &.rightAlign {
        text-align: right;
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.secondaryButton {
    @extend .primaryButton;

    font-weight: normal;
    background: none;
    border: none;
    color: #fff;
}

.thirdButton {
    @extend .secondaryButton;

    border: 1px solid rgba(0,0,0, 0.3);
    font-weight: normal;
    background: none;
    color: #000;

    &:after {
        color: #d6a76f;
    }
}


.fourthButton {
    @extend .secondaryButton;

    font-weight: bold;
    background: none;
    border: none;
    color: #fff;

    &:hover{
        background: none;

        &:after {
            color: #d6a76f;
        }
    }

    &:after {
        color: #d6a76f;
    }
}

.simpleButton {
    @extend .secondaryButton;

    font-weight: bold;
    background: none;
    outline: none;
    padding: 10px 0 !important;

    &:after {
        color: #d6a76f;
    }
    &:hover {
        background: none !important;
        color: #d6a76f !important;
        border-color: rgba(255, 255, 255, 0);

        &:after {
            margin-left: 15px;
            color: #d6a76f;
        }
    }
}

.goBack {
    @extend .secondaryButton;

    font-weight: bold;
    background: none;
    padding: 15px 0;

    span {
        border-bottom: 2px solid #d6a76f;
    }

    &:after {
        display: none;
    }

    &:before {
        transition: all 300ms ease;

        text-decoration: inherit;
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        content: '\f060';

        position: relative;
        margin-right: 10px;
        font-size: 16px;
        color: #d6a76f;
        top: 0px;
    }

    &:hover {
        background: none;
        border-color: transparent;
        color: #000 !important;
    }

    &.gold {
        &:before {
            color: #fff;
        }
    }
}

.goBackSecondary {
    @extend .secondaryButton;
    &:after {
        display: none;
    }
    &:before {
        transition: all 300ms ease;
        text-decoration: inherit;
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        content: '\f177';
        position: relative;
        margin-right: 10px;
        font-size: 16px;
        color: #000;
        top: 1px;
    }
    &:hover {
        &:before {
            color: #fff;
        }
    }
}
