.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 670px;
  margin: 0 auto;
  height: 100%;

  h1 {
    font-weight: 300;
    margin: 0 0 35px;
    color: #fff;

    @media only screen and (max-width: 520px) {
      font-size: 25px !important;
      br {
        display: none;
      }
    }

    a {
      color: #d09a59;
      text-decoration: underline;
    }
  }

  p {
    font-size: 21px;
    color: #fff;

    @media only screen and (max-width: 520px) {
      font-size: 18px;
    }
  }
}
