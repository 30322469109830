.cookie_disclaimer {
  position: fixed;
  z-index: 5;
  display: inline-block;

  .container {
    max-width: 780px;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    text-align: left;
    position: fixed;
    padding: 15px;
    z-index: 2;
    right: 25px;
    bottom: 25px;
    background: rgba(0, 0, 0, .7);
    border: 1px solid #fff;
    color: #fff;
    width: 100%;
    left: 0;
    margin: 0 auto;

    @media only screen and (max-width: 670px) {
      width: 90%;
      left: 5%;
    }
  }

  p {
    font-family: Source Sans Pro, sans-serif;
    display: inline-block;
    font-size: 11px;
    margin-top: 0;
    float: left;
    width: calc(100% - 275px);
    color: #ffffff;
    @media only screen and (max-width: 820px) {
      padding-right: 0;
      text-align: left;
      width: 100%;
    }
  }

  @media only screen and (max-width: 820px) {
    .accept_terms {
      margin: 10px;
      float: none;
      display: inline-block;
    }
  }


  @media only screen and (max-width: 670px) {
    .accept_terms {
      padding: 10px !important;
      text-align: center;
      height: auto;
      width: 100%;
      float: left;
      margin: 10px 0 0;
    }
  }

  @media only screen and (max-width: 460px) {
    padding-bottom: 15px;
    width: calc(100% - 50px);
    left: 25px;

    .container {
      text-align: center;
    }
  }

}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 780px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  text-align: left;
  position: fixed;
  padding: 15px;
  z-index: 2;
  right: 25px;
  bottom: 25px;
  background: rgba(0, 0, 0, .7);
  border: 1px solid #fff;
  color: #fff;
  width: 100%;
  left: 0;
  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
}

.buton_conditii {
  border-bottom: 1px solid #d6a76f;
  color: #fff;

  &:hover {
    color: #ffffff;
  }
}

.cookie_hint_settings {
  font-family: Source Sans Pro, sans-serif;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  float: left;
  width: auto;
  margin-top: 20px;
  color: #fff;
  border-bottom: 1px solid #d6a76f;
  font-weight: 300;

  &:hover {
    color: #ffffff;
  }

  @media only screen and (max-width: 820px) {
    margin: 20px 0 0 15px;
  }

  @media only screen and (max-width: 670px) {
    padding: 10px 0 0;
    width: 100%;
    margin: 0;
  }

}

.accept_terms {
  font-family: Source Sans Pro, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 20px;
  margin-top: 12px;
  background: #05b974 !important;
  line-height: normal !important;
  padding: 8px 35px !important;
  height: auto !important;
  color: #fff;
  float: right;
  width: auto;
}

.linksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 820px) {
    flex-direction: column;
    width: 100%;
  }
}