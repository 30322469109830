.header {
  a.logo {
    background-image: url("../../../assets/logo.png");
    background-repeat: no-repeat;
    background-size: 65%;
    text-indent: -9999px;

    transition: all 300ms ease;
    display: inline-block;
    position: absolute;
    align-self: center;
    margin-top: 28px;
    width: 150px;
    height: 24px;

    left: 25px;

    &.noAuth {
      //left: 25px !important;
    }

    @media only screen and (max-width: 1210px) {
      left: 75px;
    }

    @media only screen and (max-width: 500px) {
      left: 60px;
      top: 0;
    }

    @media only screen and (max-width: 380px) {
      width: 110px;
      left: 50px;
      top: 2px;
    }
  }

  &.isPWA {
    a.logo {
      left: 15px;
    }
  }

  &.summerTime {
    a.logo {
      background-image: url("../../../assets/images/ui/summer-logo.png");
      margin-top: 14px;
      height: 47px;
    }

    &.slim {
      a.logo {
        margin-top: 6px;
        height: 37px;
      }
    }
  }
}
