.popup{
    display: inline-block;
    position: relative;
    text-align: left;
    padding: 25px;
    color: #000;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;

    h2 {
        display: inline-block;
        font-weight: bold;
        text-align: left;
        font-size: 18px;

        margin: 0 0 10px;
        color: #000;
    }

    p{
        font-size: 12px;
    }

    span{
        text-align: left;

        ul{
            font-size: 12px;
            color: #838383;
        }
    }

    .cookieDetails{
    }

    .cookieForm{
        .control {
            display: inline-block;
            margin-bottom: 15px;
            padding-left: 30px;
            position: relative;
            cursor: pointer;
            width: 100% !important;
            font-size: 16px;
            min-width: 16px;
            color: #838383;

            p {
                font-size: 12px !important;
                font-weight: normal !important;
                text-transform: none !important;
                margin-bottom: 1rem !important;
            }

            h3 {
                position: relative;
                margin-bottom: 5px;
                top: -2px;
            }

            input {
                position: absolute;
                z-index: -1;
                opacity: 0;
            }
        }
        .control__indicator {
            background: none;
            border: 2px solid #d4d4d4;
            position: absolute;
            height: 20px;
            width: 20px;
            top: 2px;
            left: 0;

            &:after {
                content: '';
                position: absolute;
                display: none;
            }
        }
        .control input.prevChecked ~ .control__indicator:after {
            display: block;
        }
        .control input.prevChecked ~ .control__indicator {
            border-color: #d6a76f !important;
            border: none;
            background: #d6a76f !important;
        }
        .control__radio .control__indicator {
            border-radius: 50%;
        }
        .control:hover input ~ .control__indicator,
        .control input:focus ~ .control__indicator {
            background: #f1f1f1;
        }
        .control input:checked ~ .control__indicator {
            border-color: #d6a76f !important;
            border: none;
            background: #d6a76f !important;
        }
        .control input:disabled ~ .control__indicator {
            background: #e6e6e6;
            opacity: 0.6;
            pointer-events: none;
        }
        .control input:checked ~ .control__indicator:after {
            display: block;
        }
        .control__checkbox .control__indicator:after {
            left: 7px;
            top: 3px;
            width: 6px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .control__checkbox input:disabled ~ .control__indicator:after {
            border-color: #ffffff;
        }
        .control__radio .control__indicator:after {
            left: 7px;
            top: 7px;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background: #fff;
        }
        .control__radio input:disabled ~ .control__indicator:after {
            background: #7b7b7b;
        }
    }
}

:global .manageCookies{
    max-width: 560px;
    width: 90%;
}