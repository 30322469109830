/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    height: 30px;
    width: 36px;

    left: 10px;
    top: 23px;

    outline: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px !important;
    width: 24px !important;

    right: 15px !important;
    top: 15px !important;

    z-index: 9;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
    height: 24px !important;
    width: 2px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/

.isPWA{
    .bm-menu-wrap {
        width: 100% !important;
        max-width: 100%;
    }
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 95% !important;
    max-width: 350px;
    left: 0;
}

/* General sidebar styles */
.bm-menu {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#31333f+1,090919+100 */
    background: rgb(49, 51, 63); /* Old browsers */
    background: -moz-radial-gradient(
        center,
        ellipse cover,
        rgba(49, 51, 63, 1) 1%,
        rgba(9, 9, 25, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
        center,
        ellipse cover,
        rgba(49, 51, 63, 1) 1%,
        rgba(9, 9, 25, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
        ellipse at center,
        rgba(49, 51, 63, 1) 1%,
        rgba(9, 9, 25, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31333f', endColorstr='#090919',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden !important;
    display: table;
    width: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    display: table-cell;
    vertical-align: middle;
}


/* Individual item */
.bm-item {
    display: inline-block;
    text-align: left;
    outline: none;

    padding: 0;
    margin: 0;

    width: 100%;
    float: left;

    > li {
        display: inline-block;
        text-align: left;
        width: 100%;
        float: left;

        margin-bottom: 15px;

        a {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            padding: 5px 0;
            color: #fff;
        }

        ul {
            display: inline-block;
            text-align: left;
            outline: none;

            padding: 0;
            margin: 0;

            width: 100%;
            float: left;

            padding-left: 15px;

            li {
                display: inline-block;
                text-align: left;
                width: 100%;
                float: left;

                a {
                    font-weight: normal;
                    opacity: 0.5;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
    left: 0px;
}

.header{
    &.isPWA{
        .bm-burger-button{
            position: absolute;
            right: 10px;
            left: auto;
        }
    }
}
