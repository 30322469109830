.header {
  transition: all 300ms ease;
  display: table-row;
  background: #010103;
  text-align: center;
  position: fixed;
  height: 75px;
  width: 100%;
  z-index: 5;
  left: 0;
  top: 0;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#010103+0,22232e+50,010103+100 */
  background: #010103; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #010103 0%,
    #22232e 50%,
    #010103 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #010103 0%,
    #22232e 50%,
    #010103 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #010103 0%,
    #22232e 50%,
    #010103 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#010103', endColorstr='#010103', GradientType=1); /* IE6-9 */

  // @include animate(fadeInDown, 1s);

  .showLogin {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: inline-block;
    position: relative;
    line-height: 75px;
    border: none;
    color: #fff;

    transition: all 10ms ease;
    margin-right: 65px;
    float: right;
    padding: 0;

    &:after {
      //background-image: url('../../images/ui/user_icon.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      transition: all 300ms ease;
      display: inline-block;
      position: absolute;
      content: "";

      right: -35px;
      top: 25px;

      height: 21px;
      width: 19px;
    }

    @media only screen and (max-width: 470px) {
      span {
        display: none;
      }
    }
  }

  .logo {
    background-image: url("../../assets/logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    text-indent: -9999px;

    transition: all 300ms ease;
    display: inline-block;
    position: absolute;
    margin-top: 27px;
    width: 150px;
    height: 24px;

    left: 25px;

    &.noAuth {
      //left: 25px !important;
    }

    @media only screen and (max-width: 1210px) {
      left: 75px;
    }

    @media only screen and (max-width: 500px) {
      width: 100px;
      height: auto;
      left: 15px;
      top: 4px;
    }
  }

  ul.menu {
    display: inline-block;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;

      a {
        text-transform: uppercase;
        font-family: "Noto Sans";
        letter-spacing: 2px;
        position: relative;
        line-height: 75px;
        padding: 0 25px;

        font-size: 1.0625rem;
        color: #f5f5f5;
        opacity: 0.5;

        &.noLink {
          cursor: default;
        }

        &:hover {
          text-decoration: none;
          opacity: 1;
        }

        &.cartIcon {
          background-image: url("../../assets/images/ui/cart_icon.png");
          background-position: center center;
          background-repeat: no-repeat;
          text-indent: -99999px;
          display: inline-block;
          height: 35px;
          width: 30px;
          top: 17px;

          span {
            text-indent: 0px;
            top: -5px;

            &:after {
              display: none;
            }
          }
        }

        @media only screen and (min-width: 860px) {
          span {
            //@include border-radius(50%);
            background: #c81111;
            display: inline-block;
            text-align: center;
            position: absolute;
            line-height: 25px;
            height: 25px;
            width: 25px;
            color: #fff;
            z-index: -1;

            right: -24px;
            top: -10px;

            &:after {
              content: "";

              //@include border-radius(50%);
              display: inline-block;
              background: #101118;
              position: absolute;
              height: 15px;
              width: 15px;

              bottom: -1px;
              left: -8px;
            }
          }
        }
      }

      &.has-submenu {
        > a {
          position: relative;

          @media only screen and (max-width: 1060px) {
            font-weight: bold;
            opacity: 1;
          }

          &:after {
            text-decoration: inherit;
            font-family: FontAwesome;
            font-weight: normal;
            font-style: normal;

            display: inline-block;
            position: relative;

            content: "\f078";
            font-size: 9px;
            top: -3px;
            margin-left: 10px;
          }
        }
      }

      @media only screen and (min-width: 1210px) {
        ul.submenu {
          display: none;
          background: rgba(8, 8, 12, 0.8);
          text-align: center;
          position: fixed;
          width: 100%;
          z-index: 0;
          top: 75px;
          left: 0;

          li {
            a {
              letter-spacing: 2px;
              line-height: normal;
              padding: 5px 20px;
              font-size: 12px;
              color: #d6a76f;

              margin: 10px 0;
              opacity: 1;

              &:hover {
                background: #232323;
                //@include border-radius(25px);
              }
            }
          }
        }

        &:hover {
          ul.submenu {
            display: inline-block;
            //@include animate(fadeIn, 300ms);
          }
        }
      }

      @media only screen and (max-width: 1210px) {
        ul.submenu {
          text-align: left;

          li {
            a {
              line-height: normal;
              margin-bottom: 0;
              padding: 10px 0;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 1060px) {
      > li {
        > a {
          font-weight: bold !important;
          opacity: 1 !important;

          &:after {
            display: none !important;
          }
        }
      }
    }

    @media only screen and (max-width: 1540px) {
      padding-left: 185px;
      text-align: left;

      li {
        a {
          padding: 0 10px;
        }

        &.has-submenu {
          > a {
            &:after {
              right: -5px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 1410px) {
      li {
        a {
          font-size: 13px;

          &:after {
            right: 0;
          }
        }
      }
    }

    @media only screen and (max-width: 1250px) {
      li {
        a {
          font-size: 12px;
        }
      }
    }

    @media only screen and (max-width: 1210px) {
      display: none;

      //@include animate(fadeIn, 1s);
      padding: 15px 25px 15px 25px;
      position: fixed;
      z-index: 5;
      top: 73px;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#31333f+1,090919+100 */
      background: rgb(49, 51, 63); /* Old browsers */
      background: -moz-radial-gradient(
        center,
        ellipse cover,
        rgba(49, 51, 63, 1) 1%,
        rgba(9, 9, 25, 1) 100%
      ); /* FF3.6-15 */
      background: -webkit-radial-gradient(
        center,
        ellipse cover,
        rgba(49, 51, 63, 1) 1%,
        rgba(9, 9, 25, 1) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(
        ellipse at center,
        rgba(49, 51, 63, 1) 1%,
        rgba(9, 9, 25, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31333f', endColorstr='#090919',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

      &.is-visible {
        display: flex;
        width: 100vw;
        justify-content: center;
        align-self: flex-start;
        min-height: calc(100% - 75px);
        flex-direction: column;

        padding-left: 25px !important;
      }

      li {
        display: inline-block;
        width: 100%;

        a {
          display: inline-block;
          font-weight: normal;
          line-height: normal;
          color: #fff;

          font-size: 16px;
          font-family: "Source Sans Pro";

          margin-bottom: 15px;

          &:hover {
            color: #fff;
          }

          &.cartIcon {
            //@include border-radius(50%);
            background: none;
            text-indent: 0;
            height: auto;
            width: auto;
            top: 0;

            span {
              top: -5px;

              &:after {
                display: none;
              }
            }
          }
        }

        &.has-submenu {
          margin-bottom: 10px;

          > a {
            &:after {
              position: relative;
              margin-left: 10px;
              right: auto;
              top: -2px;
            }
          }

          ul.submenu {
            padding-left: 25px;
          }

          &.is-active {
            > a {
              &:after {
                content: "\f077";
              }
            }

            ul.submenu {
              display: inline-block;
            }
          }
        }
      }
    }

    @media only screen and (max-height: 640px) {
      li {
        a {
          margin-bottom: 5px;
          font-size: 13px;
        }

        ul.submenu {
          li {
            a {
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}

.fakeCoachmarkMenu {
  position: absolute;
  right: 10px;
  background: transparent;
  top: -25px;
  width: 235px;
  height: 240px;
}

.loginOverlay {
  margin-top: 75px;
  background: -moz-radial-gradient(center,ellipse cover,#474a59 0%,#0f0e1c 70%);
  background: -webkit-radial-gradient(center,ellipse cover,#474a59 0%,#0f0e1c 70%);
  background: radial-gradient(ellipse at center,#474a59 0%,#0f0e1c 70%);
  transition: all .5s cubic-bezier(.23,1,.32,1);
  background-repeat: no-repeat;
  background-size: 100vw 80vw;
  background-position: 50%;
  width: 100%;
  position: fixed;
  z-index: 4;
  opacity: 0;
  outline: 100vh solid rgba(0, 0, 0, 0.8);
  transform: translateY(-100%);

  @media only screen and (max-width: 530px) {
    background-size: auto;
  }
}

.wrapper {
  background: transparent !important;
}

.open {
  opacity: 1;
  transform: translateY(0%);
  transition: all .5s cubic-bezier(.23,1,.32,1);
}