form {
  display: inline-block;
  text-align: left;
  width: 100%;

  label:not(.control) {
    display: inline-block;
    margin-bottom: 35px;
    position: relative;
    width: 100%;
    float: left;

    @media only screen and (max-width: 720px) {
      width: 100% !important;
    }

    .form-group {
      margin-bottom: 0;
      width: 100%;
      float: left;
    }

    &.fullwidth {
      width: 100%;
    }

    // Label text
    p {
      transition: all 0.5s ease;
      text-transform: uppercase;
      display: inline-block;
      position: relative;
      overflow: hidden;
      float: left;
      width: 100%;
      color: #000;

      margin: 0 !important;
      font-weight: bold;
      font-size: 14px;
      height: auto;
      opacity: 1;

      b {
        color: $primary_color;
      }
    }

    // Input
    input,
    select {
      transition: all 0.5s ease;
      display: inline-block;
      padding: 12px 20px 12px 0;
      cursor: pointer;
      font-size: 16px;
      outline: none;
      float: left;
      width: 100%;

      -ms-appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      border: none;
      background: none;

      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      font-family: $sourcesanspro;
      // letter-spacing: 2px;
      font-weight: 500;
      font-size: 16px;
      color: #000;

      &[name="email"] {
        // text-transform: lowercase;
      }
    }

    textarea {
      border: none;
      outline-color: $primary_color;
      font-family: $sourcesanspro;
      transition: all 0.5s ease;
      display: inline-block;
      background: #fff;
      font-size: 16px;
      outline: none;
      padding: 15px;
      width: 100%;

      -ms-appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }

    input,
    select,
    textarea {
      &:focus {
        box-shadow: none;
        outline: none;

        border-color: $gold;
      }

      &:focus,
      &:not(:valid) {
        & ~ p {
          margin-top: -65px !important;
          font-size: 12px;
          opacity: 1;
        }
      }
    }

    // Error message
    span.error {
      display: none;
    }

    // Email custom icon
    &.email {
      &:before {
        content: "email";
        font-family: "Material Icons";
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;

        text-align: center;
        line-height: 45px;
        font-size: 20px;
        color: #fff;
      }
    }

    // Password custom icon
    &.password {
      &:before {
        content: "lock";
        font-family: "Material Icons";
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;

        text-align: center;
        line-height: 45px;
        font-size: 20px;
        color: #fff;
      }
    }

    // Select
    &.select {
      display: inline-block;
      margin-bottom: 35px;
      position: relative;
      width: 100%;
      float: left;

      &:after {
        content: "\f078";
        font-family: FontAwesome;
        text-decoration: inherit;
        font-weight: normal;
        font-style: normal;
        background: #fff;

        display: inline-block;
        pointer-events: none;
        position: absolute;
        text-align: center;
        line-height: 45px;
        height: 44px;
        width: 45px;
        color: #000;
        right: 1px;
        top: 19px;
      }
    }

    // No errors
    &.no-errors {
      &:before {
        background: #00bf2d;
      }

      input,
      select {
        outline-color: #00bf2d;
        border-color: #00bf2d;
      }
    }

    // Show error
    &.has-error {
      &:before {
        background: #ff144b;
      }

      input,
      select,
      textarea {
        outline-color: #ff144b;
        border-color: #ff144b;
        outline: none;
      }

      span.error {
        display: inline-block;
        padding-left: 25px;
        position: relative;
        line-height: 18px;
        text-align: left;
        margin-top: 10px;
        font-size: 14px;
        color: #ff144b;
        width: 100%;

        &:before {
          font-family: "Material Icons";
          text-decoration: inherit;
          font-weight: normal;
          font-style: normal;
          content: "info";

          display: inline-block;
          position: absolute;
          text-align: center;
          font-size: 16px;

          left: 0px;
          top: -1px;

          // IE FIX
          text-rendering: optimizeLegibility;
          font-feature-settings: "liga";
          ms-font-feature-settings: "liga";
        }
      }
    }

    // Show success

    span.success {
      display: inline-block;
      padding-left: 25px;
      position: relative;
      line-height: 18px;
      text-align: left;
      margin-top: 10px;
      font-size: 14px;
      color: #00bf2d;

      &:before {
        font-family: "Material Icons";
        text-decoration: inherit;
        font-weight: normal;
        font-style: normal;
        content: "check_circle";

        display: inline-block;
        position: absolute;
        text-align: center;
        font-size: 16px;

        left: 0px;
        top: -1px;
      }
    }
  }

  // Custom upload input
  .custom-upload {
    display: inline-block;
    position: relative;
    float: left;
    width: 49%;

    @media only screen and (max-width: 720px) {
      width: 100%;
    }

    &.is_hidden {
      display: none;
    }

    label {
      width: 100% !important;
    }

    .custom-upload__input {
      position: absolute;
      overflow: hidden;
      left: -10000px;
      height: 0;
    }

    .custom-upload__button {
      border: 2px solid $primary_color;
      background-color: transparent;
      color: $primary_color;
      text-align: center;
      line-height: 41px;
      margin-top: 24px;
      cursor: pointer;

      @include border-radius(5px);

      i {
        position: absolute;
        right: 15px;
        top: 8px;
      }

      &:hover {
        background: $primary_color;
        color: #fff;
      }
    }

    span.error {
      display: none;
      padding-left: 25px;
      position: relative;
      line-height: 18px;
      text-align: left;
      margin-top: 10px;
      font-size: 14px;
      color: #ff144b;

      &:before {
        font-family: "Material Icons";
        text-decoration: inherit;
        font-weight: normal;
        font-style: normal;
        content: "info";

        display: inline-block;
        position: absolute;
        text-align: center;
        font-size: 16px;

        left: 0px;
        top: -1px;
      }
    }

    &.has-error {
      .custom-upload__button {
        border-color: #ff144b;
      }

      span.error {
        display: inline-block;
      }
    }
  }


}

.isPWA {
  form {
    label:not(.control) {
      input {
        border-bottom: 2px solid #000;
      }
      textarea {
        border: none;
        border-bottom: 2px solid #000;
      }
    }

    label {
      select {
        border-bottom: 2px solid #000;
      }
    }
  }
}

// Checkboxes and radios
.control {
  display: inline-block;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  width: 100% !important;
  font-size: 16px;
  min-width: 16px;
  color: #838383;

  &.control--radio {
    background: rgba(0, 0, 0, 0.25);
    padding: 15px 15px 15px 45px;
    border: 1px solid #404654;
    min-height: 75px;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
      margin: 0;
    }

    &.active {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d1bb88+0,f9eac3+50,d1bb88+100 */
      background: #d1bb88; /* Old browsers */
      background: -moz-linear-gradient(
        left,
        #d1bb88 0%,
        #f9eac3 50%,
        #d1bb88 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        left,
        #d1bb88 0%,
        #f9eac3 50%,
        #d1bb88 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to right,
        #d1bb88 0%,
        #f9eac3 50%,
        #d1bb88 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1bb88', endColorstr='#d1bb88',GradientType=1 ); /* IE6-9 */

      p {
        color: #000;
      }
    }

    &:nth-of-type(1) {
      .control__indicator:before {
        content: "A";
      }
    }
    &:nth-of-type(2) {
      .control__indicator:before {
        content: "B";
      }
    }
    &:nth-of-type(3) {
      .control__indicator:before {
        content: "C";
      }
    }
    &:nth-of-type(4) {
      .control__indicator:before {
        content: "D";
      }
    }
    &:nth-of-type(5) {
      .control__indicator:before {
        content: "E";
      }
    }
    &:nth-of-type(6) {
      .control__indicator:before {
        content: "F";
      }
    }
    &:nth-of-type(7) {
      .control__indicator:before {
        content: "G";
      }
    }
  }

  h3 {
    position: relative;
    margin-bottom: 5px;
    top: -2px;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.control__indicator {
  background: #0d0d13;
  position: absolute;
  height: 30px;
  width: 30px;
  left: 0;
  top: 0;

  &:before {
    position: absolute;
    line-height: 30px;
    content: "";
    color: #fff;
    width: 100%;
    left: 0;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.control input.prevChecked ~ .control__indicator:after {
  display: block;
}

.control input.prevChecked ~ .control__indicator {
  border-color: $primary_color !important;
  border: none;
  background: $primary_color !important;
}

.control--radio .control__indicator {
  //border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #d6a76f;
}
.control input:checked ~ .control__indicator {
  background: rgba(0, 0, 0, 0.1) !important;
}

.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control input:checked ~ .control__indicator:after {
  //display: block;
}

.control--checkbox .control__indicator:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}

// .control__indicator {
//     background: none;
//     border: 2px solid #d4d4d4;
//     position: absolute;
//     height: 20px;
//     width: 20px;
//     top: 2px;
//     left: 0;

//     &:after {
//         content: '';
//         position: absolute;
//         display: none;
//     }
// }

// .control input.prevChecked ~ .control__indicator.classic:after {
//     display: block;
// }

// .control input.prevChecked ~ .control__indicator.classic {
//     border-color: $primary_color !important;
//     border: none;
//     background: $primary_color !important;
// }

// .control--radio .control__indicator {
//     border-radius: 50%;
// }
// .control:hover input ~ .control__indicator,
// .control input:focus ~ .control__indicator {
//     background: #f1f1f1;
// }
// .control input:checked ~ .control__indicator.classic {
//     border-color: $primary_color !important;
//     border: none;
//     background: $primary_color !important;
// }

// .control input:disabled ~ .control__indicator.classic {
//     background: #e6e6e6;
//     opacity: 0.6;
//     pointer-events: none;
// }

// .control input:checked ~ .control__indicator.classic:after {
//     display: block;
// }

// .control--checkbox .control__indicator.classic:after {
//     left: 7px;
//     top: 3px;
//     width: 6px;
//     height: 10px;
//     border: solid #fff;
//     border-width: 0 2px 2px 0;
//     transform: rotate(45deg);
// }
// .control--checkbox input:disabled ~ .control__indicator.classic:after {
//     border-color: #7b7b7b;
// }
// .control--radio .control__indicator.classic:after {
//     left: 7px;
//     top: 7px;
//     height: 6px;
//     width: 6px;
//     border-radius: 50%;
//     background: #fff;
// }
// .control--radio input:disabled ~ .control__indicator.classic:after {
//     background: #7b7b7b;
// }

// .g-recaptcha{ margin: 10px 0; }

.App {
  &.isPWA {
    form {
      label:not(.control) {
        margin-bottom: 25px;

        input,
        select {
          border-bottom: 2px solid #1f1e2f;
        }

        &.select {
          &:after {
            color: #d6a76f;
          }
        }
      }
    }
    .control {
      padding-left: 40px;
    }

    .control__indicator {
      border: 2px solid #1f1e2f;
      height: 25px;
      width: 25px;
    }

    .control--checkbox .control__indicator:after {
      left: 10px;
      top: 6px;
    }
  }
}

// Disable OSx default styling
input,
select,
textarea {
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
