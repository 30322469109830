.loginContainer {
    position: fixed;
    padding: 0 50px;
    display: table;
    height: 100vh;
    width: 100%;
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    background: #000;
    color: #fff;

    @media only screen and (max-width: 520px) {
        padding: 0 25px;
    }

    //.close-pp {
    //    background-image: url('../../assets/images/ui/esc.png');
    //    background-repeat: no-repeat;
    //    background-size: cover;
    //
    //    display: inline-block;
    //    position: absolute;
    //    height: 31px;
    //    width: 30px;
    //
    //    top: 25px;
    //    right: 25px;
    //    cursor: pointer;
    //
    //    @media only screen and (max-width: 520px) {
    //        width: 20px;
    //        height: 20px;
    //    }
    //}

    .logo {
        margin-bottom: 50px;
        width: 200px;
    }

    h3 {
        font-weight: 300;
        font-size: 36px;
        color: #fff;

        margin: 0;
        padding: 0;

        u {
            // text-transform: uppercase;
            text-decoration: none;
            font-size: 14px;
            margin-top: 5px;
            display: block;
            color: #fff;
        }

        @media only screen and (max-width: 520px) {
            line-height: 25px;
            font-size: 25px;
        }
    }

    p {
        margin-top: 35px;
        font-weight: 300;
        max-width: 400px;
        font-size: 18px;
        color: #fff;

        @media only screen and (max-width: 520px) {
            line-height: 18px;
            font-size: 16px;
        }
    }

    a.forgotPassword {
        padding-left: 0;

        &:after {
            display: none;
        }

        &:before {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            font-family: FontAwesome;
            text-decoration: inherit;
            font-weight: normal;
            font-style: normal;
            content: '\F177';

            margin-right: 10px;
            position: relative;
            color: #000;
            left: 0;
        }

        &:hover {
            &:before {
                margin-right: 15px;
            }
        }
    }

    a.register {
        color: rgba(255, 255, 255, 0.35);
        text-transform: uppercase;
        display: inline-block;
        letter-spacing: 1px;
        margin-top: 35px;
        font-size: 14px;
        width: 100%;

        b {
            color: #fff;
        }

        @media only screen and (max-width: 520px) {
            margin-top: 25px;
        }

        &:after {
            transition: all 300ms ease;
            text-decoration: inherit;
            font-family: FontAwesome;
            font-weight: normal;
            font-style: normal;
            content: '\f178';
            position: relative;
            margin-left: 10px;
            font-size: 16px;
            color: #d6a76f;
            top: 1px;
        }

        &:hover {
            text-decoration: none;
            color: #fff;

            &:after {
                margin-left: 15px;
            }
        }
    }

    form {
        text-align: center;
        max-width: 350px;
        margin-top: 35px;

        @media only screen and (max-width: 520px) {
            margin-top: 15px;
        }

        label {
            display: inline-block;
            margin-bottom: 35px;
            width: 100%;

            @media only screen and (max-width: 520px) {
                margin-bottom: 10px;
            }

            p {
                text-transform: uppercase;
                letter-spacing: 1px;
                text-align: left;
                font-weight: 300;
                font-size: 16px;
                color: #fff;
            }

            input,
            select,
            textarea {
                border: none;
                background: none;

                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                font-family: 'Source Sans Pro';
                letter-spacing: 2px;
                font-weight: 300;
                font-size: 16px;
                color: #fff;

                &:focus {
                    box-shadow: none;
                    outline: none;

                    border-color: #fff;
                }

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: rgba(255, 255, 255, 0.5);
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: rgba(255, 255, 255, 0.5);
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }

        button[type='submit'] {
            //@extend a.primary_button;

            background: rgba(0, 0, 0, 0.4);
            border: 1px solid #92949a;
            font-size: 14px;
            color: #92949a;
            width: auto;

            &:hover {
                border-color: #d6a76f;
                color: #fff;
            }

            @media only screen and (max-width: 520px) {
                width: 100%;
            }
        }
    }
}
