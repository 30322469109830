.formContainer {
  display: inline-block;
  width: 100%;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  border-radius: 10px;
  height: 50px;
}

.formContainer + .input {
  background-color: red !important;
}

.icon {
  margin-right: 30px;
}

.secondaryIcon {
  float: right;
  width: 24px;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;

  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
}

.secondaryIconInactive {
  opacity: 0.45;
}

.field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;

  input,
  select {
    border: none;
    background-color: transparent;
    height: 30px;
    color: #fff;

    @-webkit-keyframes autofill {
      to {
        color: #666;
        background: transparent;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #1a1d24 inset !important;
      -webkit-text-fill-color: #fff !important;
    }
  }

  option {
    background-color: white;
    color: black;
  }
}

.isSelect {
  .field {
    select:disabled {
      cursor: not-allowed;
      opacity: 0.2;
      bottom: 10px;
    }
    select {
      position: relative;
      z-index: 1;
    }
  }

  .field:after {
    content: "\f078";
    font-family: FontAwesome;
    text-decoration: inherit;
    font-weight: normal;
    font-style: normal;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.field .input:focus,
.field .input:active,
.field .input:required {
  box-shadow: none;
  outline: none;
  margin-bottom: 0;
  font-size: 16px;
  color: #fff;
  width: 100%;
}

.field .input + .label {
  pointer-events: none;
  position: absolute;
  font-size: 16px;
  bottom: 0.1em;
  left: 0.1em;
  color: #fff;
  margin-bottom: 8px;
  transition: margin 0.2s ease, color 0.2s ease, font-size 0.2s ease;
  //text-transform: capitalize;
  text-align: left;
  z-index: -1;
}
.field .input[type="date"] {
  color: transparent;
}
.field .input[type="date"]:focus,
:required {
  color: white;
}

.field .input:focus + .label,
.field .input:active + .label,
.field .input:valid + .label {
  pointer-events: auto;
  margin-bottom: 2.2em !important;
  font-weight: 400;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.field .input:focus + .label,
.field .input:active + .label {
  font-weight: 400;
}

.field .input.empty:not(:focus) + .label,
.field .input.empty:not(:active) + .label {
  pointer-events: none;
  margin-bottom: 0;
  font-size: 1em;
  color: #667188;
}
.info {
  display: flex;
  justify-self: start;
  font-size: small;
  color: red;
  margin-top: -16px;
}
