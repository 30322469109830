.container {
  width: 90%;

  button {
    max-width: 300px;
  }
  .ant-checkbox-wrapper {
    justify-self: start;
    display: flex;
  }

  .form {
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
      position: relative;
      width: calc(45%);
      margin-bottom: 18px;

      div {
        width: 100%;
        display: flex;
        .ant-radio-group {
          color: rgba(255, 255, 255, 0.5);
          justify-content: space-around;
          display: flex;
        }
        select:invalid {
          color: rgba(255, 255, 255, 0.5);
          bottom: 10px;
          cursor: pointer;
        }
      }
    }
    @media screen and(max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      div {
        width: 90%;
      }
    }
  }

  h1 {
    text-transform: uppercase;
    font-family: "Noto Sans";
    margin: 35px 0;
    font-size: 25px;
    color: #d6a76f;

    @media only screen and (max-width: 520px) {
      font-size: 25px !important;
      line-height: normal !important;
    }
  }

  h3 {
    text-transform: uppercase;
    font-family: "Noto Sans";
    text-align: center;
    font-size: 18px;
    width: 100%;
  }

  p {
    color: #fff;

    span {
      font-weight: bold;
      color: #d6a76f;
    }
  }
}
